<template>
  <div
    class="text-with-icons"
    :class="borderedClass"
  >
    <UiHeader
      v-if="header"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
    />

    <div class="text-with-icons__wrapper">
      <template
        v-for="image in flatGallery"
        :key="image.id"
      >
        <component
          :is="haveContent(image) ? 'div' : haveLink(image) ? 't3-link' : 'div'"
          :to="image.properties.linkData"
          class="text-with-icons__item"
          :class="{ icon: !haveContent(image) }"
        >
          <div class="text-with-icons__icon-wrapper">
            <UiImg
              :image="image"
              :decorative="haveContent(image)"
              class="text-with-icons__icon"
            />
          </div>

          <T3HtmlParser
            v-if="image.properties.title"
            :tag="image.properties.description ? 'h3' : 'div'"
            class="text-with-icons__title"
            :content="image.properties.title"
          />

          <T3HtmlParser
            v-if="image.properties.description"
            class="text-with-icons__desc"
            :content="image.properties.description"
          />

          <ReadMore
            v-if="haveContent(image)"
            :button="{ link: image.properties.linkData }"
            :lang="lang"
            class="text-with-icons__button"
          />
        </component>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { T3File } from '@t3headless/nuxt-typo3'
import { computed } from 'vue'

import { ReadMore, UiHeader, UiImg } from '~ui/components'
import useFlatGallery from '~ui/composables/useFlatGallery'
import { isLinkValid } from '~ui/helpers/linkUtils'
import type { UiCeTextGalleryProps } from '~ui/types'

const props = defineProps<UiCeTextGalleryProps>()

const flatGallery = useFlatGallery(props)

const borderedClass = computed(() =>
  props.gallery?.border?.enabled ? 'text-with-icons--bordered' : ''
)

const haveLink = (image: T3File) => {
  return isLinkValid(image.properties.linkData)
}
const haveContent = (image: T3File) => {
  return !!(image.properties.title || image.properties.description)
}
</script>

<style lang="scss">
.text-with-icons {
  margin: spacing(lg) auto;
  display: flex;
  gap: spacing(xl);
  flex-direction: column;
  align-items: center;
  text-align: left;

  &__wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: stretch;
    gap: spacing(md);

    @include media-query(sm) {
      display: grid;
      grid-template-columns: repeat(2, minmax(rem(200px), 1fr));
    }

    @include media-query(md) {
      grid-template-columns: repeat(3, minmax(rem(200px), 1fr));
    }
  }

  &__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: rem(25px);
    border-radius: em(4px);
    transition:
      background-color 0.3s ease-in-out,
      box-shadow 0.3s ease-in-out;

    &:hover:not(.static) {
      background-color: color(alabaster);
      box-shadow: 0px 4px 32px hsl(0 0% 0% / 15%);
    }
  }

  &__icon {
    height: 100%;
    width: 100%;
    object-fit: contain;

    &-wrapper {
      flex-grow: 1;
      height: auto;
      width: auto;
    }
  }

  &__title {
    margin-block: rem(15px) auto;
    font-weight: 600;
    font-size: rem(24px);
    line-height: 1.2;
    height: 100%;
  }

  &__desc {
    margin-top: rem(10px);

    & > p:last-child {
      margin-bottom: 0;
    }
  }

  &__button {
    margin-top: rem(20px);
  }

  $base: &;

  &--bordered {
    padding: rem(32px);
    border-radius: em(4px);
    background-color: hsl(0 0% 85% / 12%);
    gap: rem(24px);

    #{$base}__wrapper {
      grid-template-columns: repeat(2, 1fr);
      gap: rem(32px) rem(24px);
    }

    #{$base}__item {
      background-color: color(white);
      border: 1px solid hsl(0 0% 0% / 8%);

      &:hover:not(.static) {
        background-color: color(white);
        box-shadow:
          0px 8px 16px 0px hsl(0 0% 0% / 5%),
          0px 2px 20px 0px hsl(0 0% 0% / 4%);
      }
    }

    & #{$base}__item.icon {
      padding: rem(36px) rem(48px);

      & #{$base}__icon-wrapper {
        margin-inline: auto;
      }
    }

    div > #{$base}__icon-wrapper {
      display: inline-block;
      border-radius: em(4px);
      padding: rem(12px);
      background-color: color(primary);
      height: rem(64px);
      width: rem(64px);

      & img {
        filter: brightness(0) invert(1);
      }
    }

    #{$base}__title {
      margin-block: rem(32px) rem(6px);
      font-weight: 700;
      font-size: rem(20px);
    }

    #{$base}__desc {
      font-size: rem(14px);

      p {
        font-size: inherit;
      }
    }

    #{$base}__button a {
      color: color(black);

      &:is(:hover, :active, :focus) {
        color: color(primary);
      }
    }
  }
}
</style>
